import CortRom from "../assets/img/cortinas/cortinasRomanas/cortinas-romanas-0.jpg";
import CortRom1 from "../assets/img/cortinas/cortinasRomanas/cortinas-romanas-1.jpg";
import CortRom2 from "../assets/img/cortinas/cortinasRomanas/cortinas-romanas-2.jpg";
import CortRom3 from "../assets/img/cortinas/cortinasRomanas/cortinas-romanas-3.jpg";
import CortRom4 from "../assets/img/cortinas/cortinasRomanas/cortinas-romanas-4.jpg";
import CortRom5 from "../assets/img/cortinas/cortinasRomanas/cortinas-romanas-5.jpg";
import CortRom6 from "../assets/img/cortinas/cortinasRomanas/cortinas-romanas-6.jpg";
import CortRom7 from "../assets/img/cortinas/cortinasRomanas/cortinas-romanas-7.jpg";

const cortinasRom = [
  CortRom,
  CortRom1,
  CortRom2,
  CortRom3,
  CortRom4,
  CortRom5,
  CortRom6,
  CortRom7,
];
export default cortinasRom;
