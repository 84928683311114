import CorTripShade from "../assets/img/cortinas/cortinasTripleShade/cortinas-triple-0.jpg";
import CorTripShade1 from "../assets/img/cortinas/cortinasTripleShade/cortinas-triple-1.jpg";
import CorTripShade2 from "../assets/img/cortinas/cortinasTripleShade/cortinas-triple-2.jpg";
import CorTripShade3 from "../assets/img/cortinas/cortinasTripleShade/cortinas-triple-3.jpg";
import CorTripShade4 from "../assets/img/cortinas/cortinasTripleShade/cortinas-triple-4.jpg";
import CorTripShade5 from "../assets/img/cortinas/cortinasTripleShade/cortinas-triple-5.jpg";
import CorTripShade6 from "../assets/img/cortinas/cortinasTripleShade/cortinas-triple-6.jpg";
import CorTripShade7 from "../assets/img/cortinas/cortinasTripleShade/cortinas-triple-6.jpg";

const cortinasTrip = [
  CorTripShade,
  CorTripShade1,
  CorTripShade2,
  CorTripShade3,
  CorTripShade4,
  CorTripShade5,
  CorTripShade6,
  CorTripShade7,
];
export default cortinasTrip;
