import CortEnroll from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-0.jpg";
import CortEnroll1 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-1.jpg";
import CortEnroll2 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-2.jpg";
import CortEnroll3 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-3.png";
import CortEnroll4 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-4.jpg";
import CortEnroll5 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-5.jpg";
// import CortEnroll6 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-6.jpg";
// import CortEnroll7 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-7.jpg";
// import CortEnroll8 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-8.jpg";
// import CortEnroll9 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-9.jpg";
// import CortEnroll10 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-10.jpg";
// import CortEnroll11 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-11.jpg";
// import CortEnroll12 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-12.jpg";
// import CortEnroll13 from "../assets/img/cortinas/cortinasEnrollables/cortinas-enrollables-13.jpg";

const cortinasEnrollables = [
  CortEnroll,
  CortEnroll1,
  CortEnroll2,
  // CortEnroll3,
  // CortEnroll4,
  // CortEnroll5,
  // CortEnroll6,
  // CortEnroll7,
  // CortEnroll8,
  // CortEnroll9,
  // CortEnroll10,
  // CortEnroll11,
  // CortEnroll12,
  // CortEnroll13,
];
export default cortinasEnrollables;
