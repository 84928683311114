import CortPer from "../assets/img/cortinas/cortinasPermasExterioresBalcones/cortinas-permas-0.jpg";
import CortPer1 from "../assets/img/cortinas/cortinasPermasExterioresBalcones/cortinas-permas-1.jpg";
import CortPer2 from "../assets/img/cortinas/cortinasPermasExterioresBalcones/cortinas-permas-2.jpg";
import CortPer3 from "../assets/img/cortinas/cortinasPermasExterioresBalcones/cortinas-permas-3.jpg";
import CortPer4 from "../assets/img/cortinas/cortinasPermasExterioresBalcones/cortinas-permas-4.jpg";
import CortPer5 from "../assets/img/cortinas/cortinasPermasExterioresBalcones/cortinas-permas-5.jpg";
import CortPer6 from "../assets/img/cortinas/cortinasPermasExterioresBalcones/cortinas-permas-6.jpeg";

const cortinasPer = [
  CortPer,
  CortPer1,
  CortPer2,
  CortPer3,
  CortPer4,
  CortPer5,
  CortPer6,
];
export default cortinasPer;
