
import car1 from "../assets/img/carousel/carousel1.jpg";
import car2 from "../assets/img/carousel/carousel2.jpg";
import car3 from "../assets/img/carousel/carousel3.jpg";
import car4 from "../assets/img/carousel/carousel4.jpg";
import car5 from "../assets/img/carousel/carousel5.jpg";
import car6 from "../assets/img/carousel/carousel6.jpg";
import car7 from "../assets/img/carousel/carousel7.jpg";
import car8 from "../assets/img/carousel/carousel8.jpg";

const carousel = [car1, car2, car3, car4, car5, car6, car7, car8];
export default carousel;
