import CortCap from "../assets/img/cortinas/cortinasDeslizantes/cortinas-deslizantes-0.jpg";
import CortCap1 from "../assets/img/cortinas/cortinasDeslizantes/cortinas-deslizantes-1.jpg";
import CortCap2 from "../assets/img/cortinas/cortinasDeslizantes/cortinas-deslizantes-2.jpg";
import CortCap3 from "../assets/img/cortinas/cortinasDeslizantes/cortinas-deslizantes-3.jpg";
import CortCap4 from "../assets/img/cortinas/cortinasDeslizantes/cortinas-deslizantes-4.jpg";
import CortCap5 from "../assets/img/cortinas/cortinasDeslizantes/cortinas-deslizantes-5.jpg";
import CortCap6 from "../assets/img/cortinas/cortinasDeslizantes/cortinas-deslizantes-6.jpg";

const cortinasCap = [
  CortCap,
  CortCap1,
  CortCap2,
  CortCap3,
  CortCap4,
  CortCap5,
  CortCap6,
];
export default cortinasCap;
