import CortZeb from "../assets/img/cortinas/cortinasNeoluxZebras/cortinas-zebras-0.jpg";
import CortZeb1 from "../assets/img/cortinas/cortinasNeoluxZebras/cortinas-zebras-1.jpg";
import CortZeb2 from "../assets/img/cortinas/cortinasNeoluxZebras/cortinas-zebras-2.jpg";
import CortZeb3 from "../assets/img/cortinas/cortinasNeoluxZebras/cortinas-zebras-3.jpg";
import CortZeb4 from "../assets/img/cortinas/cortinasNeoluxZebras/cortinas-zebras-4.jpg";
import CortZeb5 from "../assets/img/cortinas/cortinasNeoluxZebras/cortinas-zebras-5.jpg";
import CortZeb6 from "../assets/img/cortinas/cortinasNeoluxZebras/cortinas-zebras-6.jpg";
import CortZeb7 from "../assets/img/cortinas/cortinasNeoluxZebras/cortinas-zebras-7.jpg";

const cortinasCap = [
  CortZeb,
  CortZeb1,
  CortZeb2,
  CortZeb3,
  CortZeb4,
  CortZeb5,
  CortZeb6,
  CortZeb7,
];
export default cortinasCap;
